@value colorMainTextFont  from "src/configuration/theme/legacycss/colors.css";
@value colorBlue  from "src/configuration/theme/legacycss/colors.css";
@value smallFontSize: 12px;
@value baseFontSize: 14px;
@value mediumFontSize: 16px;
@value largeFontSize: 20px;
@value baseLineHeight: 1.19em;

@value fontWeightLighter: 300;
@value fontWeightLight: 400;
@value fontWeightNormal: 400;
@value fontWeightBold: 600;

/* ---------------------------------------------------------------------------------

Only globals and all globals MUST belong here
this file needs to be imported at all entry points, e.g. the app, storybook, test init

This file is split into two sections.

Section 1 contains globals that are meant to be applied across all projects. Changes in this section
should be considered a core change and should also be applied to specsheet.

Section 2 contains only truly project-wide styles. First consider whether they can belong in just a widget
This section can contain styles that immediately override Section 1 styles.

--------------------------------------------------------------------------------- */

/* --------- Section 1. Reset CSS intended to applied across all projects. ---------*/

html {
  overflow-y: hidden;
  font-size: smallFontSize;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button:focus,
input:focus {
  outline: 0;
}

button {
  cursor: pointer;
  background: inherit;
}

/* widget specific (most likely). Could be 'global reset css', if so, leave in this file */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html > body,
select {
  /*  font-family: "Inter", "Helvetica Neue", Helvetica, Arial, system, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-size: baseFontSize;
  line-height: baseLineHeight;
}

.light {
  font-weight: fontWeightLight;
}

b,
strong,
.bold {
  font-weight: fontWeightBold;
}

a {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3 {
  font-weight: fontWeightBold;
}

/** End of semantic UI specific resets */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  line-height: baseLineHeight;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

.caption {
  font-size: smallFontSize;
}

.mediumTextSize {
  font-size: mediumFontSize;
  line-height: 20px;
}

button {
  font-family: "Inter";
}

/* ---------
  Section 2.X Colors should only contain:
    color
--------- */

html > body,
select {
  color: colorMainTextFont;
}

a {
  color: colorBlue;
}

/* ---------
  Section 2.X Cursors should only contain:
    cursor
--------- */

a,
button {
  cursor: pointer;
}
