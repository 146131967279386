@value unit from "src/configuration/theme/legacycss/metrics.css";
@value borderWidth from "src/configuration/theme/legacycss/metrics.css";
@value borderRadius from "src/configuration/theme/legacycss/metrics.css";

@value noSelect from "src/configuration/theme/legacycss/utils.css";

@value bold from "src/configuration/theme/legacycss/globals.css";
@value smallFontSize from "src/configuration/theme/legacycss/globals.css";

@value colorIconTextFont from "src/configuration/theme/legacycss/colors.css";
@value colorIconTextFont25 from "src/configuration/theme/legacycss/colors.css";
@value colorBlue25 from "src/configuration/theme/legacycss/colors.css";
@value colorBlue from "src/configuration/theme/legacycss/colors.css";
@value colorBorder from "src/configuration/theme/legacycss/colors.css";
@value colorNavy from "src/configuration/theme/legacycss/colors.css";

@value cellPadding: unit;
@value tableFontSize: smallFontSize;

.table {
  width: 100%;
  border-collapse: collapse;
  border: borderWidth solid colorBorder;
  font-size: tableFontSize;
}

.table.middleAlign td {
  vertical-align: middle;
}

.table.topAlign td {
  vertical-align: top;
}

.table.bottomAlign td {
  vertical-align: bottom;
}

.thead {
}

.thead .tableCell {
  /* NOTE: `height` has same behavior as `min-height` for table cells */
  height: 44px;
  padding: cellPadding;
  color: colorIconTextFont;
  text-align: left;
}

.tableHeaderCell {
  display: flex;
  align-items: center;
}

.tbody {
}

.tbody .tableCell {
  /* NOTE: `height` has same behavior as `min-height` for table cells */
  height: 35px;
  padding: cellPadding;
}

.tableRow {
  border-bottom: borderWidth solid colorBorder;
}

.tbody .tableRow:last-child {
  border-bottom: 0;
}

.clickable,
.clickableHeader {
  cursor: pointer;
  composes: noSelect;
}

.tableCell.clickable:hover {
  color: colorBlue;
  /** Note: CSS modules does not allow composition when using multiple classes or hover states */
  font-family: "Inter";
}

.tableRow.clickable:hover {
  background: colorBlue25;
}

.icon {
  margin-left: calc(unit/2);
}

/** New rows animations */
.highlightRow {
  /** CSS Transitions group does not support using composes from CSS modules (complains about html space characters)*/
  font-family: "Inter";
  color: colorBlue;
}

.fadeRow {
  opacity: 1;
}

.fadeRow.fadeRowActive {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

.sorting {
  color: colorNavy;
  composes: bold;
}
