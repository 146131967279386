@value unit from "src/configuration/theme/legacycss/metrics.css";

@value borderWidth from "src/configuration/theme/legacycss/metrics.css";

@value colorSecondaryTextFont from "src/configuration/theme/legacycss/colors.css";

@value caption from "src/configuration/theme/legacycss/globals.css";
@value bold from "src/configuration/theme/legacycss/globals.css";

@value modalSideContentPadding from "src/ui/components/modal/modal.css";
@value modalContentPadding from "src/ui/components/modal/modal.css";

.container {
  margin: calc(2 * unit) 0;
  overflow: auto;
}

.content {
  text-align: center;
}

.header {
  padding: modalContentPadding;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: unit;
}

.title {
  composes: bold;
  margin-bottom: 0;
}
